<template>
  <div class="lookingInvestmentContainer">
    <div class="pageImgBox">
      <el-image style="width: 100%;" :src="url" flt="cover" :preview-src-list="srcList"></el-image>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "https://file.thinkervc.cn/b5cbaf08d81c404eac579b461e14320f.png",
      srcList: ["https://file.thinkervc.cn/b5cbaf08d81c404eac579b461e14320f.png"]
    };
  },
  methods: {},
  components: {}
};
</script>

<style scoped lang="scss">
  .lookingInvestmentContainer{
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
  }
  .pageImgBox{
    width: 100%;
    height: auto;
    padding: 50px 0;
  }
</style>
